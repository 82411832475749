// const pre=`http://localhost:2995/`;
const pre=`http://5.189.166.187:5551/GameTriviaGamesBackend/`;
// const pre =`https://backendapi.bigcash.co.za/`; //we use this domain instead of ip address

let redirectApi=`${pre}Redirect`;
export{redirectApi};

let getTermsApi=`${pre}sendTerms`;
export{getTermsApi};

let gameIdUniversal=`2`; //2 for telkom wingame
export{gameIdUniversal};